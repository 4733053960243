import React from 'react';
import classNames from 'classnames';
import { IPosition, PositionType } from '../../../__mocks__/bagMerger';

interface ILoadPlanPosition {
  position: IPosition;
}

const BULK_SHORT = 'BLK';

const LoadPlanPosition = ({ position }: ILoadPlanPosition) => {
  const { uld, bulks, name, type } = position;
  const isRight = type === PositionType.RIGHT;
  const isLeft = type === PositionType.LEFT;
  const isBulk = type === PositionType.BULK;

  const positionClassName = classNames(
    'relative flex flex-col justify-end border-[1px] rounded-8 h-full p-[10px]',
    {
      'bg-grey-12 border-grey-12': !uld,
      'bg-white border-primary': uld,
      'justify-center text-center': isBulk && !uld,
    }
  );
  const containerClassName = classNames(
    'text-primary relative overflow-visible  flex flex-col w-[160px]',
    {
      'row-span-1 row-start-2': isRight,
      'row-span-1 row-start-3': isLeft,
      'row-span-2 row-start-2': isBulk,
    }
  );

  const positionContainer = classNames('px-[15px] h-full', {
    'pt-[10px] ': isRight,
    'pt-[10px] pb-[10px] ': isLeft,
    'border-black  pt-[10px] pb-[10px]': isBulk,
  });

  const circleClassName = classNames(
    'absolute w-[25px] h-[25px] border-[1px] border-primary rounded-full top-[10px] right-[10px]',
    {
      hidden: !uld,
    }
  );

  const nameAndPos = `${name} ${isRight ? 'R' : isLeft ? 'L' : ''}`;

  return (
    <div className={containerClassName}>
      <p
        className={classNames({
          'absolute -translate-y-[40px]  self-center text-16':
            isRight || isBulk,
        })}>
        {isRight ? nameAndPos : ''}
      </p>
      <div className={positionContainer}>
        <div className={positionClassName}>
          <div className={circleClassName}></div>
          {isBulk && !uld ? (
            <p className="text-primary font-bold text-grey-90">{BULK_SHORT}</p>
          ) : (
            <>
              <p className="text-primary font-bold text-grey-90">{uld}</p>
              <p className="text-base text-grey-90 mt-2">
                {bulks?.type} {bulks?.pieces}
              </p>
              <p className="text-base text-grey-90 ">{bulks?.destination}</p>
            </>
          )}
        </div>
      </div>
      <p
        className={classNames({
          'absolute translate-y-[22px]  bottom-0 self-center text-16':
            isLeft || isBulk,
        })}>
        {isLeft || isBulk ? nameAndPos : ''}
      </p>
    </div>
  );
};

export default LoadPlanPosition;
