import React from 'react';
import { ICompartment, PositionType } from '../../../__mocks__/bagMerger';
import LoadPlanPosition from './LoadPlanPosition';
import { createUniqueId } from '../../../utils/createUniqueId';

interface ILoadPlanCompartment {
  compartment: ICompartment;
}

const LoadPlanCompartment = ({ compartment }: ILoadPlanCompartment) => {
  const { positions, remainingWeight } = compartment;
  const totalCompartments = compartment.positions.filter(
    (item) =>
      item.type === PositionType.LEFT ||
      item.type === PositionType.BULK ||
      item.type === PositionType.PALLET
  ).length;

  const containerClassName = `border-t-[1px] border-b-[1px] border-primary h-[100%] w-fit grid grid-rows-3 grid-rows-[18px_auto_auto] grid-cols-${totalCompartments}`;
  const remainingClassName = `col-span-${totalCompartments} text-center text-primary`;
  return (
    <div
      className={containerClassName}
      style={{
        gridTemplateColumns: `repeat(${totalCompartments}, 160px)`,
      }}>
      <p className={remainingClassName}> Remaining {remainingWeight} kg</p>
      {positions.map((position) => (
        <LoadPlanPosition key={createUniqueId()} position={position} />
      ))}
    </div>
  );
};

export default LoadPlanCompartment;
