import React from 'react';
import Button from '../Button/Button';
import { getFlightName } from '../../utils/helpers';
import { BAG_MERGER } from '../../utils/constants';
import classNames from 'classnames';
import LoadPlan from './LoadPlan/LoadPlan';
import { bagMergerMocks } from '../../__mocks__/bagMerger';

interface IBagMerger {
  handleCloseBagMerger: () => void;
  handleSend: () => void;
  flightId: string;
}

const BagMerger = ({
  handleCloseBagMerger,
  handleSend,
  flightId,
}: IBagMerger) => {
  const titleChild =
    'ml-0 mr-auto transform -rotate-90 text-18 text-primary dark:text-white font-body-text font-semibold';
  const buttonClassName =
    'w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center';

  //after load plan response is received, we should concatenate type+subtype to title

  const { aircraftSubType, aircraftType, decks } = bagMergerMocks;
  const title = `${getFlightName(flightId)} ${aircraftType}${
    aircraftSubType ? `-${aircraftSubType}` : ``
  }`;

  return (
    <div className="h-full overflow-y-scroll overflow-x-hidden flex flex-col dark:bg-grey-90">
      <div className="p-16 flex items-center border-b-grey-12 border-b-1 pb-16">
        <div className="flex gap-[10px]">
          <div className="text-18 text-primary dark:text-white font-body-text font-semibold">
            {BAG_MERGER.TITLE}
          </div>
          <div className="text-18 text-primary dark:text-white  font-body-text">
            {title}
          </div>
        </div>
        <Button
          text={BAG_MERGER.SAVE}
          className={classNames(buttonClassName, 'mr-0 ml-auto')}
          textClassName="text-14 text-primary dark:text-primary font-body-text leading-[18px]"
          onClick={handleSend}
        />
        <Button
          text={BAG_MERGER.CANCEL}
          className={classNames(buttonClassName, 'mr-0 ml-16')}
          textClassName="text-14 text-primary dark:text-primary  font-body-text leading-[18px]"
          onClick={handleCloseBagMerger}
        />
      </div>
      <div className="grid grid-rows-2 h-full">
        <div className="grid grid-flow-col auto-cols-auto items-center">
          <div className={titleChild}>{BAG_MERGER.LOAD_PLAN}</div>
          <div className="w-full h-full overflow-y-scroll pt-[20px] pb-[20px]">
            <LoadPlan deck={decks[0]} />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <div className={titleChild}>{BAG_MERGER.PREPARE_ULDS}</div>
          <p className="ml-0 mr-auto text-center">Here will be prepare ULDs</p>
        </div>
      </div>
    </div>
  );
};

export default BagMerger;
