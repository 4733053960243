import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Title from '../Title/Title';
import TeamContactItem from './TeamContactItem';
import {
  CheckedInResponsableRole,
  selectCheckedInUsers,
} from '../../redux/reducers/checkinReducer';
import { useGetCheckedInRoles } from '../../utils/hooks/useGetCheckedInRoles';
import classNames from 'classnames';
import ListHeader from '../Chat/ChatHeader/ChatHeader';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { FLIGHT_SEARCH, NO_ROLE_CHECKEDIN } from '../../utils/constants';
import { CheckInUserType } from '../../utils/generated/graphql';

const TITLE = 'CONTACT';

interface ITeamContact {
  flightId?: string;
  title?: string;
  isOnTelephony?: boolean;
  showEmptyMessage?: boolean;
  handleClose?: () => void;
  handleBack?: () => void;
}

const TeamContact = ({
  flightId,
  title,
  isOnTelephony = false,
  showEmptyMessage = false,
  handleClose,
  handleBack,
}: ITeamContact) => {
  const checkedInUsers = useSelector(selectCheckedInUsers);
  const { flight } = useParams();
  const currentFlight = flightId ?? flight;
  const { data } = useGetCheckedInRoles(currentFlight);
  const headerClass = classNames('text-primary text-16 dark:text-grey-12', {
    'text-18 font-head-bold': isOnTelephony,
  });

  const containerClass = classNames(
    'flex flex-1 flex-col bg-white dark:bg-grey-90 h-full',
    {
      'px-0 gap-y-0 py-0': isOnTelephony,
      'rounded-8 py-32 px-16 mobile:gap-y-32 gap-y-32 h-min': !isOnTelephony,
    }
  );
  const noFlightsClassNames =
    'self-center m-auto font-head-bold text-grey-25 text-24';

  const filterRoles = (roles: CheckedInResponsableRole[]) => {
    const filteredRoles = isOnTelephony
      ? roles.filter((role) => role.checkedInUsers.length)
      : roles.map((role) => ({
          ...role,
          checkedInUsers: role.checkedInUsers.filter(
            (user) => user.userType === CheckInUserType.TAC
          ),
        }));
    return filteredRoles;
  };

  const roles = useMemo(() => {
    if (currentFlight && checkedInUsers[currentFlight]) {
      return filterRoles(
        checkedInUsers[currentFlight]?.roles?.filter((role) => role.canCall)
      );
    } else if (data && data.length) {
      return filterRoles(
        (data
          ?.flatMap((role) => role?.roles)
          ?.filter((role) => role?.canCall) as CheckedInResponsableRole[]) ?? []
      );
    }
    return [];
  }, [checkedInUsers, data, currentFlight]);

  const renderHeaderTelephony = () => {
    return (
      <ListHeader handleClose={handleClose}>
        <Button
          Icon={
            <Icon
              height={14}
              width={14}
              variant="arrowBack"
              className="fill-primary dark:fill-white"
            />
          }
          className="flex items-center gap-8 tablet:hidden laptop:hidden desktop:hidden"
          textClassName="text-primary font-head-bold text-14 dark:text-white"
          onClick={handleBack}
        />
        <div className={headerClass}>{!showEmptyMessage && title}</div>
      </ListHeader>
    );
  };

  const renderMappedRoles = () => {
    return roles?.map((role) => {
      return (
        <TeamContactItem
          key={role.name}
          role={role}
          isOnTelephony={isOnTelephony}
        />
      );
    });
  };

  const renderRolesTelephony = () => {
    return isOnTelephony ? (
      roles.length ? (
        renderMappedRoles()
      ) : (
        <div className={noFlightsClassNames}>{NO_ROLE_CHECKEDIN}</div>
      )
    ) : (
      renderMappedRoles()
    );
  };
  return (
    <div className={containerClass}>
      {isOnTelephony ? (
        renderHeaderTelephony()
      ) : (
        <Title title={TITLE} className={'ml-24'}></Title>
      )}
      {!showEmptyMessage ? (
        renderRolesTelephony()
      ) : (
        <div className={noFlightsClassNames}>
          {FLIGHT_SEARCH.NO_FLIGHT_STATION}
        </div>
      )}
    </div>
  );
};

export default TeamContact;
