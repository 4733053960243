import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import classNames from 'classnames';
import MyFlights from '../../components/MyFlights/MyFlights';
import {
  CONFIRM_CHANGES,
  EDIT_MY_FLIGHTS,
  INITIAL_COUNTDOWN_VALUE,
  MUTE_ALL,
  MY_FLIGHTS,
  NOTIFICATION_MSG,
  NO_CHANGES,
  ONE_SECOND_IN_MILLISECONDS,
  UNMUTE_ALL,
} from '../../utils/constants';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import { useNotifications } from '../../utils/hooks/useNotifications';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { ERRORS } from '../../utils/constants';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { useSearchParams } from 'react-router-dom';
import {
  updateFlightNotificationsHistory,
  removeFlightNotifications,
} from '../../redux/reducers/newsfeedReducer';
import { useGetMyFlights } from '../../utils/hooks/useGetMyFlights';
import {
  MyFlight,
  CheckedInResponsableRole,
} from '../../utils/generated/graphql';
import { useRemoveMyFlight } from '../../utils/hooks/useRemoveMyFlights';
import { GET_MY_FLIGHTS } from '../../graphql/myFlights/my-flights.queries';
import Dropdown, {
  ALL_OPTION,
  Option,
} from '../../components/Dropdown/Dropdown';
import { clearNotifications } from '../../redux/reducers/notificationReducer';
import { arraysEqual } from '../../utils/arrayCompare';
import { useUpdateMyFlights } from '../../utils/hooks/useUpdateMyFlights';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import ReloadButton from '../../components/CustomButtons/ReloadButton/ReloadButton';
import { setShouldFetchCheckedinFlights } from '../../redux/reducers/checkinReducer';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';

const MY_FLIGHTS_2_ENABLED = FeatureFlagUtil.showFeature(
  process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
  []
);

interface IFlightsToBeMuted {
  notificationMuted: boolean;
  flightId: string;
}

interface UpdatedFlights {
  flightId: string;
}

export interface IMyFlightEdit {
  flightId: string;
  notificationMuted?: boolean;
  isCheckedIn?: boolean;
  isFavourite?: boolean;
  checkedInRole?: string | null;
}

interface MyFlightWithCheckin extends MyFlight {
  checkedInRole?: string | null;
}

type ICheckedInRoles = CheckedInResponsableRole | null;

export interface UpdatedFlightsCheckin extends IMyFlightEdit {
  checkedInRoles?: ICheckedInRoles[] | null;
}

const headerClass =
  'ml-[24px] mb-[39px] mt-[34px] font-head-light text-18 leading-[19px] text-grey-60 dark:text-grey-40 mobile:hidden';
const buttonTextClassName =
  'text-primary font-head-bold text-14 leading-[18px] text-[700]';

const getUpdatedList = (
  originalFlights: MyFlight[],
  updatedFlights: UpdatedFlights[]
): MyFlight[] => {
  return originalFlights.map((originalFlight) => {
    const updatedFlight = updatedFlights.find(
      (updatedFlight) => updatedFlight.flightId === originalFlight.flightId
    );
    if (updatedFlight) {
      return { ...originalFlight, ...updatedFlight };
    }
    return originalFlight;
  });
};

const getUpdatedListCheckin = (
  originalFlights: MyFlight[],
  updatedFlights: UpdatedFlightsCheckin[] | null
): MyFlight[] => {
  if (!updatedFlights) return originalFlights;
  return originalFlights
    .filter((originalFlight) => {
      const updatedFlight = updatedFlights.find(
        (updatedFlight) => updatedFlight.flightId === originalFlight.flightId
      );
      if (
        updatedFlight &&
        !updatedFlight.checkedInRole &&
        !updatedFlight.isFavourite
      ) {
        return false;
      }
      return true;
    })
    .map((originalFlight) => {
      const updatedFlight = updatedFlights.find(
        (updatedFlight) => updatedFlight.flightId === originalFlight.flightId
      );
      if (updatedFlight) {
        return {
          ...originalFlight,
          ...updatedFlight,
        };
      }
      return originalFlight;
    });
};

const Favourites = () => {
  const { data, loading, error, refetchMyFlights } = useGetMyFlights();
  const isOnline = useNetworkStatus();
  const isLoading = isOnline && loading;
  const { onUpdateFlights, loading: loadingEdit } = useUpdateMyFlights();
  const [flightList, setFlightList] = useState<MyFlightWithCheckin[]>([]);
  const [flightListEdit, setFlightListEdit] = useState<IMyFlightEdit[]>([]);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [selectedOpts, setSelectedOptions] = useState<string[]>([]);
  const [isEditable, setIsEditable] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [countdown, setCountdown] = useState(INITIAL_COUNTDOWN_VALUE);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditing = searchParams.get('editing');

  const mappedFlightList =
    flightList?.map((flight) => ({
      flightId: flight.flightId ?? '',
      notificationMuted: flight.notificationMuted ?? false,
      isCheckedIn: flight.isCheckedIn ?? false,
      isFavourite: flight.isFavourite ?? false,
      checkedInRole: flight.role?._id ?? flight.checkedInRole ?? null,
    })) ?? [];

  useEffect(() => {
    if (isOnline) {
      const intervalId = setInterval(() => {
        if (countdown > 0) {
          setCountdown((prev) => --prev);
        } else {
          dispatch(clearNotifications());
          refetchMyFlights({
            stationsList: selectedOpts,
          }).then(() => {
            setCountdown(INITIAL_COUNTDOWN_VALUE);
          });
        }
      }, ONE_SECOND_IN_MILLISECONDS);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [countdown, dispatch, isOnline, refetchMyFlights, selectedOpts]);

  useEffect(() => {
    !loading && setIsFirstRender(true);
  }, [loading]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (MY_FLIGHTS_2_ENABLED) {
      setFlightList(data ?? []);
    } else {
      setFlightList(data?.filter((flight) => flight.isFavourite) ?? []);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (!isEditing) {
      setFlightListEdit(mappedFlightList);
    }
  }, [flightList]);

  const allOptions = useMemo(() => {
    const stationsArray =
      flightList?.map((flight) => {
        return {
          label: flight.origin?.toString() ?? '',
          value: flight.origin?.toString() ?? '',
        };
      }) ?? [];
    const returnedArray = [
      ...new Map(stationsArray.map((item) => [item['label'], item])).values(),
    ];
    setOptions(returnedArray);
    return returnedArray;
  }, [isFirstRender]);

  useEffect(() => {
    if (!isLoading && !isEditable) {
      const stationsArray =
        data?.map((flight) => flight.origin?.toString() ?? '') ?? [];
      const array = stationsArray.filter(
        (value, index) => stationsArray.indexOf(value) === index
      );
      if (selectedOpts.length > 0) {
        const toRemove = selectedOpts.filter(
          (station) => !array.includes(station)
        );
        toRemove.length > 0 &&
          setOptions(
            allOptions.filter((station) => !toRemove.includes(station.label))
          );
      } else {
        setOptions(
          allOptions.filter((station) => array.includes(station.label))
        );
      }
    }
  }, [isEditable, isLoading]);

  const onOptionsChange = useCallback(
    (options: Option[]) => {
      const selectedOptions = !options.includes(ALL_OPTION)
        ? options.map((option: Option) => option.label)
        : [];
      setSelectedOptions(selectedOptions);
      refetchMyFlights({
        stationsList: selectedOptions,
      }).then(() => setCountdown(INITIAL_COUNTDOWN_VALUE));
    },
    [refetchMyFlights]
  );

  const { onRemoveFromFavourites } = useRemoveMyFlight({
    refetchQueries: [GET_MY_FLIGHTS],
  });
  const pendingMutedFlights = useRef<string[]>([]);
  const pendingRemovedFlights = useRef<string[]>([]);

  useCustomHeader({ headerTypeMobile: 'simple', title: MY_FLIGHTS });

  const { addError, addSuccess } = useNotifications(
    error,
    ERRORS.NO_FAVOURITE_FLIGHTS
  );

  const displayMessage = (response) => {
    if (response && 'error' in response) {
      dispatch(addError(ERRORS.COULD_NOT_SAVE));
    } else {
      dispatch(addSuccess(NOTIFICATION_MSG.SAVED));
    }
  };

  const handleEditConfirm = async () => {
    if (isEditable) {
      if (
        !pendingRemovedFlights.current.length &&
        !pendingMutedFlights.current.length
      ) {
        dispatch(addSuccess(NO_CHANGES));
        setIsEditable((prev) => !prev);
        setSearchParams({});
        return;
      }
      pendingRemovedFlights.current.forEach((a) => {
        const index = pendingMutedFlights.current.indexOf(a);
        if (index !== -1) {
          pendingMutedFlights.current.splice(index, 1);
        }
      });

      if (pendingRemovedFlights.current.length) {
        await onRemoveFromFavourites(pendingRemovedFlights.current);
        pendingRemovedFlights.current = [];
      }

      if (pendingMutedFlights.current.length) {
        const flights = pendingMutedFlights.current.map((el: string) => {
          const currentFlight = flightList?.find(
            (f: MyFlight) => f.flightId === el
          );
          return {
            flightId: el,
            notificationMuted: !currentFlight?.notificationMuted,
            isCheckedIn: currentFlight?.isCheckedIn ?? false,
            isFavourite: currentFlight?.isFavourite ?? true,
          };
        });

        const response = await onUpdateFlights(flights);

        const flightsToBeMuted = flights
          .filter((el: IFlightsToBeMuted) => el.notificationMuted)
          .map((el: IFlightsToBeMuted) => el.flightId);

        const flightsToBeUnmuted = flights
          .filter((el: IFlightsToBeMuted) => !el.notificationMuted)
          .map((el: IFlightsToBeMuted) => el.flightId);

        flightsToBeUnmuted.length &&
          dispatch(updateFlightNotificationsHistory());
        flightsToBeMuted.length &&
          dispatch(removeFlightNotifications(flightsToBeMuted));

        displayMessage(response);
        pendingMutedFlights.current = [];

        if (!response || 'error' in response) return;
        const updatedList = getUpdatedList(flightList, flights);
        setFlightList(updatedList);
      }

      setIsEditable((prev) => !prev);
      setSearchParams({});
    } else {
      setIsEditable((prev) => !prev);
      const params = {
        editing: 'true',
      };
      setSearchParams(params);
    }
  };

  const handleEditConfirmCheckin = async () => {
    if (isEditable) {
      if (arraysEqual(flightListEdit, mappedFlightList)) {
        dispatch(addSuccess(NO_CHANGES));
        setIsEditable((prev) => !prev);
        setSearchParams({});
        return;
      }

      const response = await onUpdateFlights(flightListEdit, dispatch);

      const flightsToBeMuted = flightListEdit
        .filter((el: MyFlight) => el.notificationMuted)
        .map((el: MyFlight) => el.flightId);

      const flightsToBeUnmuted = flightListEdit
        .filter((el: MyFlight) => !el.notificationMuted)
        .map((el: MyFlight) => el.flightId);

      flightsToBeUnmuted.length && dispatch(updateFlightNotificationsHistory());
      flightsToBeMuted.length &&
        dispatch(removeFlightNotifications(flightsToBeMuted));

      displayMessage(response);

      if (response && 'error' in response) return;
      const updatedList = getUpdatedListCheckin(flightList, response ?? []);

      setFlightList(updatedList);

      setIsEditable((prev) => !prev);
      setSearchParams({});
      dispatch(setShouldFetchCheckedinFlights(true));
    } else {
      setIsEditable((prev) => !prev);
      const params = {
        editing: 'true',
      };
      setSearchParams(params);
    }
  };

  const areAllFlightsMuted = flightList
    ?.filter((flight) => flight.isFavourite)
    .every((flight: MyFlight) => flight.notificationMuted);

  const handleMuteAll = (notificationMuted: boolean) => async () => {
    if (flightList?.length) {
      const flightListMute = flightListEdit.map((flight) => {
        if (flight.isFavourite || MY_FLIGHTS_2_ENABLED) {
          return {
            ...flight,
            notificationMuted,
          };
        }
        return flight;
      });
      const response = await onUpdateFlights(flightListMute);

      displayMessage(response);
      if (!response || 'error' in response) return;
      const updatedList = getUpdatedListCheckin(flightList, response);
      setFlightList(updatedList);
    }
  };

  useEffect(() => {
    if (!isEditing) {
      setIsEditable(false);
      pendingMutedFlights.current = [];
      pendingRemovedFlights.current = [];
    }
  }, [isEditing]);

  const calculateButtonText = () => {
    if (isEditable && loadingEdit) {
      return <LoadingSpinner width={25} height={25} />;
    } else if (isEditable && !loadingEdit) {
      return CONFIRM_CHANGES;
    } else if (!isEditable) {
      return EDIT_MY_FLIGHTS;
    }
  };

  return (
    <div className="h-full flex flex-col mobile:pt-[56px]">
      <div className="sticky mobile:top-[56px] bg-grey-6 dark:bg-black z-30">
        <div className="mobile:relative mobile:left-0 w-fit absolute mobile:top-[16px] top-8 z-40 left-[63px] right-0 mx-auto">
          {isLoading && !!flightList.length ? (
            <LoadingSpinner />
          ) : (
            !!flightList.length &&
            isOnline && <ReloadButton refetchFunctions={[refetchMyFlights]} />
          )}
        </div>
        <div className="flex top-0 w-full bg-grey-6 relative z-10 dark:bg-black justify-between">
          <h1 className={headerClass}>{MY_FLIGHTS}</h1>
          {FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_MY_FLIGHTS_EDIT_FEATURE_DISABLED ?? '',
            []
          ) && (
            <div className="flex pt-32 pb-16 pl-24 pr-24 mobile:w-full justify-between">
              <Button
                Icon={
                  !loadingEdit ? (
                    <Icon
                      width={17}
                      height={17}
                      variant={isEditable ? 'checkmark' : 'pen'}
                      className={classNames('mr-8 dark:fill-white', {
                        'fill-white': isEditable,
                      })}
                    />
                  ) : null
                }
                text={calculateButtonText()}
                textClassName={
                  isEditable
                    ? classNames(buttonTextClassName, 'text-white')
                    : classNames(buttonTextClassName, 'text-primary')
                }
                className={classNames('flex items-center justify-center', {
                  'mobile:ml-0 ml-24 w-[169px] h-[44px] bg-primary rounded-4':
                    isEditable,
                  'mobile:mr-0 mobile:ml-0 ml-[50px] h-[44px]': !isEditable,
                  'opacity-50 cursor-none pointer-events-none': !isOnline,
                })}
                onClick={
                  MY_FLIGHTS_2_ENABLED
                    ? handleEditConfirmCheckin
                    : handleEditConfirm
                }
              />
              <Button
                Icon={
                  <Icon
                    width={17}
                    height={17}
                    variant={areAllFlightsMuted ? 'mute' : 'unmute'}
                    className="fill-primary mr-8 dark:fill-white"
                  />
                }
                onClick={
                  areAllFlightsMuted
                    ? handleMuteAll(false)
                    : handleMuteAll(true)
                }
                className={classNames(
                  'mobile:ml-0 flex items-center justify-center',
                  { 'opacity-50 cursor-none pointer-events-none': !isOnline }
                )}
                text={areAllFlightsMuted ? UNMUTE_ALL : MUTE_ALL}
                textClassName={buttonTextClassName}
              />
              <Dropdown
                options={options}
                onSelectionChange={onOptionsChange}
                className={classNames('ml-[50px] mobile:ml-0', {
                  'opacity-50 cursor-none pointer-events-none': !isOnline,
                })}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && !flightList.length ? (
        <LoadingSpinner extraClassNames="z-10" />
      ) : (
        <MyFlights
          flightDataList={flightList || []}
          isEditable={isEditable}
          pendingMutedFlights={pendingMutedFlights.current}
          pendingRemovedFlights={pendingRemovedFlights.current}
          flightListEdit={flightListEdit}
          setFlightListEdit={setFlightListEdit}
        />
      )}
    </div>
  );
};

export default Favourites;
