export enum PositionType {
  BULK = 'BULK',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  PALLET = 'PALLET',
}

interface IBulk {
  pieces: number;
  type: string;
  destination: string;
}

export interface IPosition {
  name: string;
  type: PositionType;
  uld: string;
  grossWeight: string;
  bulks?: IBulk;
}

export interface ICompartment {
  name: string;
  remainingWeight: number;
  //   ventilation: string;
  //   temperature: string;
  positions: IPosition[];
}

export interface IHold {
  ventilation: string;
  temperature: string;
  name: string;
  compartments: ICompartment[];
}

export interface IDeck {
  name: string;
  holds: IHold[];
}

interface ILoadPlan {
  name: string;
  flightId: string;
  decks: IDeck[];
  aircraftType: string;
  aircraftSubType: string;
}
export const bagMergerMocks: ILoadPlan = {
  name: 'LH 1840/02',
  flightId: '20241202LH-01840-MUC-VLC',
  aircraftType: 'A330',
  aircraftSubType: '300',
  decks: [
    {
      name: 'Deck 1',
      holds: [
        {
          name: 'Hold 1',
          ventilation: 'ON',
          temperature: '19-25',
          compartments: [
            {
              name: '5',
              remainingWeight: 3500,

              positions: [
                {
                  name: '53',
                  type: PositionType.BULK,
                  uld: 'AKE',
                  grossWeight: '11',
                  bulks: {
                    pieces: 11,
                    type: 'BT',
                    destination: 'FRA',
                  },
                },
                {
                  name: '52',
                  type: PositionType.BULK,
                  uld: '',
                  grossWeight: '0',
                },
                {
                  name: '51',
                  type: PositionType.BULK,
                  uld: '',
                  grossWeight: '0',
                },
              ],
            },
          ],
        },
        {
          name: 'Hold 2',
          ventilation: 'ON',
          temperature: '19-25',
          compartments: [
            {
              name: '4',
              remainingWeight: 3500,

              positions: [
                {
                  name: '44',
                  type: PositionType.RIGHT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 28,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },
                {
                  name: '44',
                  type: PositionType.LEFT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 27,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },

                {
                  name: '43',
                  type: PositionType.RIGHT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 28,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },
                {
                  name: '43',
                  type: PositionType.LEFT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 27,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },

                {
                  name: '42',
                  type: PositionType.RIGHT,
                  uld: '',
                  grossWeight: '',
                },
                {
                  name: '42',
                  type: PositionType.LEFT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 11,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },
              ],
            },

            {
              name: '3',
              remainingWeight: 3500,

              positions: [
                {
                  name: '33',
                  type: PositionType.RIGHT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 28,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },
                {
                  name: '33',
                  type: PositionType.LEFT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 27,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },
                {
                  name: '32',
                  type: PositionType.RIGHT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 28,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },
                {
                  name: '32',
                  type: PositionType.LEFT,
                  uld: 'AKE',
                  grossWeight: '30',
                  bulks: {
                    pieces: 27,
                    type: 'BT',
                    destination: 'MUC',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
