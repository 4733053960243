import React from 'react';
import classNames from 'classnames';

interface ITailwingProps {
  airlineKey: string;
  size?: 'xs' | 's' | 'm' | 'l';
  isOpaque?: boolean;
  className?: string;
}

const Tailwing = ({
  airlineKey = 'lh',
  size = 'l',
  isOpaque = false,
  className,
  ...others
}: ITailwingProps) => {
  let width = 0,
    height = 0;

  switch (size) {
    case 'xs':
      width = 84;
      height = 75;
      break;
    case 's':
      width = 144;
      height = 124;
      break;
    case 'm':
      width = 191;
      height = 164;
      break;
    case 'l':
      width = 222;
      height = 191;
      break;
  }

  const wingsPath = {
    en: 'EN.webp',
    '4y': '4Y.webp',
    lh: 'LH.webp',
    vl: 'LH.webp',
    lx: 'LX.webp',
    os: 'OS.webp',
    sn: 'SN.webp',
  };

  return (
    <img
      src={`/wings/${wingsPath[airlineKey] || wingsPath.lh}`}
      style={{ width, height }}
      alt={`${airlineKey}-wing`}
      className={classNames(
        className,
        'object-cover [overflow-clip-margin:unset]',
        {
          'opacity-25': isOpaque,
        }
      )}
    />
  );
};

export default Tailwing;
