import { createSlice } from '@reduxjs/toolkit';
import {
  ADMIN_ROLE,
  AUTOMATIC,
  TIMEZONE_SETTINGS,
} from '../../utils/constants';
import { RootState } from '../store';
import { Role, RoleGroup } from '../../utils/generated/graphql';

const initialState = {
  isLoading: false,
  error: null,
  useUTC: TIMEZONE_SETTINGS.SET_UTC,
  use24: TIMEZONE_SETTINGS.SET_24,
  station: '',
  stationsList: [],
  theme: AUTOMATIC,
  name: '',
  firstName: '',
  phoneNumber: '',
  email: '',
  userName: '',
  isFormError: false,
  favoriteFlightsMuted: false,
  stationMuted: false,
  roles: [],
  chatMuted: false,
  nativeNotificationsMuted: false,
  isPhoneReminderActive: false,
  isLoginFlowComplete: false,
};

export const settingsReducer = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    timeToUTC(state) {
      state.useUTC = true;
    },
    timeToLocal(state) {
      state.useUTC = false;
    },
    setFormError: (state, action) => {
      state.isFormError = action.payload;
    },

    getUserSettingsStart(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    getUserSettingsSuccess(state, action) {
      const payload = action.payload;

      return {
        ...state,
        isLoading: false,
        name: payload.name,
        firstName: payload.firstName,
        phoneNumber: payload.phoneNumber,
        email: payload.email,
        userName: payload.userId,
        theme: payload.theme || AUTOMATIC,
        use24: TIMEZONE_SETTINGS.SET_24,
        useUTC: payload.useUTC,
        station: payload.station,
        stationsList: payload.stationsList,
        favoriteFlightsMuted: payload.favoriteFlightsMuted,
        stationMuted: payload.stationMuted,
        roles: payload.roles,
        chatMuted: payload.chatMuted,
        nativeNotificationsMuted:
          window?.Notification &&
          Notification.permission &&
          Notification.permission === 'granted'
            ? payload.nativeNotificationsMuted
            : true,
        isPhoneReminderActive: payload.isPhoneReminderActive,
      };
    },
    getUserSettingsFailure(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    setUserSettingsStart(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    setUserSettingsSuccess(state, action) {
      const payload = action.payload;

      return {
        ...state,
        isLoading: false,
        userName: payload.userId,
        name: payload.name,
        email: payload.email,
      };
    },
    updateUserSettingsSuccess(state, action) {
      const payload = action.payload;
      return {
        ...state,
        isLoading: false,
        firstName: payload.firstName,
        phoneNumber: payload.phoneNumber,
        theme: payload.theme,
        use24: TIMEZONE_SETTINGS.SET_24,
        useUTC: payload.useUTC,
        station: payload.station,
        stationsList: payload.stationsList,
        favoriteFlightsMuted: payload.favoriteFlightsMuted,
        stationMuted: payload.stationMuted,
        roles: payload.roles,
        chatMuted: payload.chatMuted,
        nativeNotificationsMuted:
          window?.Notification &&
          Notification.permission &&
          Notification.permission === 'granted'
            ? payload.nativeNotificationsMuted
            : true,
        isPhoneReminderActive: payload.isPhoneReminderActive,
        userName: payload.userName,
        isLoginFlowComplete: true,
      };
    },
    setUserSettingsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSettings(state) {
      state.station = initialState.station;
      state.stationsList = initialState.stationsList;
      state.name = initialState.name;
      state.firstName = initialState.firstName;
      state.email = initialState.email;
      state.phoneNumber = initialState.phoneNumber;
      state.theme = initialState.theme;
      state.useUTC = initialState.useUTC;
      state.use24 = initialState.use24;
      state.userName = initialState.userName;
      state.favoriteFlightsMuted = initialState.favoriteFlightsMuted;
      state.stationMuted = initialState.stationMuted;
      state.nativeNotificationsMuted = initialState.nativeNotificationsMuted;
      state.isPhoneReminderActive = initialState.isPhoneReminderActive;
    },

    setLoginFlowCompleted(state) {
      return {
        ...state,
        isLoginFlowComplete: true,
      };
    },
  },
});

export const {
  resetSettings,
  timeToUTC,
  timeToLocal,
  // timeTo24,
  // timeTo12,
  setFormError,
  getUserSettingsSuccess,
  getUserSettingsFailure,
  setUserSettingsStart,
  setUserSettingsSuccess,
  setUserSettingsFailure,
  updateUserSettingsSuccess,
  setLoginFlowCompleted,
} = settingsReducer.actions;
export const selectStation = (state: RootState) =>
  state.settingsReducer.station;
export const selectStationsList = (state: RootState) =>
  state.settingsReducer.stationsList;
export const selectName = (state: RootState) => state.settingsReducer.name;
export const selectFirstName = (state: RootState) =>
  state.settingsReducer.firstName;
export const selectPhone = (state: RootState) =>
  state.settingsReducer.phoneNumber;
export const selectEmail = (state: RootState) => state.settingsReducer.email;
export const selectUserName = (state: RootState) =>
  state.settingsReducer.userName;
export const selectTheming = (state: RootState) => state.settingsReducer.theme;
export const selectUTC = (state: RootState) => state.settingsReducer.useUTC;
export const select24Format = (state: RootState) => state.settingsReducer.use24;
export const selectIsFormError = (state: RootState) =>
  state.settingsReducer.isFormError;
export const selectFavoriteFlightsMuted = (state: RootState) =>
  state.settingsReducer.favoriteFlightsMuted;
export const selectNativeNotificationsMuted = (state: RootState) =>
  state.settingsReducer.nativeNotificationsMuted;
export const selectStationMuted = (state: RootState) =>
  state.settingsReducer.stationMuted;
export const selectChatMuted = (state: RootState) =>
  state.settingsReducer.chatMuted;
export const selectPhoneReminder = (state: RootState) =>
  state.settingsReducer.isPhoneReminderActive;
export const selectRoles = (state: RootState): Role[] =>
  state.settingsReducer.roles;
export const selectPermissions = (state: RootState): string[] => [
  ...new Set(
    state.settingsReducer.roles.flatMap((role: Role) => role.permissions)
  ),
];
export const selectSettingsData = (state: RootState) => state.settingsReducer;

export default settingsReducer.reducer;
