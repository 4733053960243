import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  FLIGHT_STATUS,
  FLIGHT_BLOCK_COPY,
  EMPTY_STATE,
  MOBILE_BREAKPOINT,
  DEVICE_TYPE,
} from '../../../utils/constants';
import Badge from '../../Badge/Badge';
import Grid from '../../Grid/Grid';
import Icon from '../../Icon/Icon';
import PulseNotificationIcon from '../../PulseNotificationIcon/PulseNotificationIcon';
import Tailwing from '../../Tailwing/Tailwing';
import Title from '../../Title/Title';
import ProgressBar from './ProgressBar/ProgressBar';
import NotificationsOverlay from './NotificationOverlay/NotificationOverlay';
import { BADGE_CONFIG, ERROR_BADGE } from '../../../utils/components_config';
import useCountdown from '../../../utils/hooks/useCountdown';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectFavoriteFlightsMuted,
  selectUTC,
} from '../../../redux/reducers/settingsReducer';
import { calculateTime } from '../../../utils/calculateTime';
import { MyFlight } from '../../../utils/generated/graphql';
import { IMyFlightEdit } from '../../../pages/Favourites/Favourites';
import { getAirlineKey } from '../../../utils/helpers';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';
import FeatureFlagUtil from '../../../utils/FeatureFlagUtil';
import { useDeviceBreakpoint } from '../../../utils/hooks/useDeviceBreakpoint';

const badgeClass = 'dark:bg-grey-100 bg-grey-6 w-[39px] h-[24px]';
const badgeTextClass = 'dark:text-grey-12 text-primary font-[400] text-12';
const iconClass = 'flex self-center mx-8 dark:fill-grey-12';

const flightTitleClass =
  'dark:text-grey-12 font-head-bold font-[700] text-40 text-primary leading-[40px] mobile:leading-[26px]  mobile:text-21 mobile:whitespace-nowrap mobile:min-w-[120px] mobile:max-w-[120px]';
const originDestinationClass =
  'dark:text-grey-12 text-primary font-head-light font-[300] text-18 mobile:text-16 leading-[19px]';
const subTitleClass =
  'text-primary dark:text-grey-12 leading-[19px] font-[300] text-18 mobile:text-16 font-head-light';
const delayClass = 'font-[400] text-red dark:text-red text-12 leading-[12px]';
const titleColorClass = 'dark:text-grey-40 text-grey-40';
const titleClass = 'max-h-[37px] flex flex-col gap-4';
const errorClass = 'flex justify-center my-[13px]';
const routeClass =
  'flex items-center h-24 mobile:min-w-[110px] mobile:max-w-[110px]';

const MY_FLIGHTS_2_ENABLED = FeatureFlagUtil.showFeature(
  process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
  []
);
export interface IFlightDataObject extends MyFlight {
  checkedIn?: boolean;
  tags?: string;
  checkedInRole?: string | null;
}

interface IFlightBlockProps {
  flightData: IFlightDataObject;
  isFromHomepage?: boolean;
  isEditable?: boolean;
  isMuted?: boolean;
  error?: string | null;
  flightListEdit: IMyFlightEdit[];
  setFlightListEdit: Dispatch<SetStateAction<IMyFlightEdit[]>>;
  addFlightOnMuteList?: (flight: string) => void;
  addFlightOnRemoveList?: (flight: string) => void;
  isCompactView: boolean;
}

const FlightBlock = ({
  flightData: {
    flightId,
    countdown,
    pos,
    flightStatus,
    flightName,
    isCheckedIn = false,
    origin,
    destination,
    type,
    gate,
    registration,
    processes,
    tags,
    departureTimes,
    notificationMuted = false,
    isFavourite,
    role,
    checkedInRole,
  },
  isFromHomepage = false,
  isEditable = false,
  error = null,
  flightListEdit,
  setFlightListEdit,
  addFlightOnMuteList,
  addFlightOnRemoveList,
  isCompactView,
  ...others
}: IFlightBlockProps) => {
  const timeLeft = useCountdown(countdown?.timeLeft ?? '');
  const useUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const isFavouriteFlightsMuted = useSelector(selectFavoriteFlightsMuted);
  const time = calculateTime(useUTC, is24Format, departureTimes) ?? EMPTY_STATE;
  const [isFavouriteLocally, setIsFavouriteLocally] = useState(
    MY_FLIGHTS_2_ENABLED ? isFavourite : true
  );
  const defaultMutedState = isFavourite
    ? notificationMuted
    : isFavouriteFlightsMuted;
  const [isMutedLocally, setIsMutedLocally] = useState(
    MY_FLIGHTS_2_ENABLED ? defaultMutedState : notificationMuted
  );
  const isOnline = useNetworkStatus();

  const renderTitle = (title, subtitle, children?) => {
    return (
      <Title
        className={titleClass}
        title={title}
        titleColorClass={titleColorClass}>
        <p className={subTitleClass}>{subtitle || EMPTY_STATE}</p>
        {children}
      </Title>
    );
  };

  const renderFlightDetailsMobile = () => {
    return (
      <Grid col={[3, 118, 16]} className="pb-[22px]">
        {renderTitle(FLIGHT_BLOCK_COPY.GATE, gate)}
        {renderTitle(FLIGHT_BLOCK_COPY.POS, pos)}
        {renderTitle(
          countdown?.timeLogicUsed || '',
          time,
          <>
            {!!countdown?.delay && (
              <p className={delayClass}>+{countdown.delay}min</p>
            )}
          </>
        )}
        {renderTitle(FLIGHT_BLOCK_COPY.TYPE, type)}
        {renderTitle(FLIGHT_BLOCK_COPY.REGISTRATION, registration)}
      </Grid>
    );
  };

  const renderFlightDetailsTabletAndDesktop = () => {
    return (
      <Grid
        col={[2, 118, 44]}
        className={classNames('pb-[32px]', {
          'mb-[68px]': isFromHomepage,
        })}>
        {renderTitle(FLIGHT_BLOCK_COPY.TYPE, type)}
        {renderTitle(FLIGHT_BLOCK_COPY.REGISTRATION, registration)}
        {renderTitle(FLIGHT_BLOCK_COPY.GATE, gate)}
        {renderTitle(FLIGHT_BLOCK_COPY.POS, pos)}
        {renderTitle(
          countdown?.timeLogicUsed || '',
          time,
          <>
            {!!countdown?.delay && (
              <p className={delayClass}>+{countdown.delay}min</p>
            )}
          </>
        )}
      </Grid>
    );
  };

  if ('20240201LH-00108-FRA-MUC' === flightId)
    console.log('isMutedLocally', isMutedLocally);

  useEffect(() => {
    setIsMutedLocally(defaultMutedState);
    if (!isEditable) {
      setIsFavouriteLocally(isFavourite);
    }
  }, [isEditable, defaultMutedState]);

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  const flightTitleContainer = classNames({
    'flex mb-[24px] items-center': isMobile,
    'flex flex-col gap-[8px] items-start': !isMobile,
  });

  const flightBlockContainer = classNames(
    'cursor-pointer relative justify-self-center flex flex-col justify-between w-[350px] mobile:w-full rounded-8 bg-white dark:bg-grey-90',
    {
      'mobile:h-[unset]': !isFromHomepage,
      'h-[551px] mobile:h-[unset]': isFromHomepage,
    }
  );

  const flightBlockContainerCompactView = classNames(
    'cursor-pointer relative justify-self-center flex flex-col justify-between w-[350px] mobile:w-full rounded-8 bg-white dark:bg-grey-90',
    {
      'h-[511px] mobile:h-[unset]': !isFromHomepage,
      'h-[551px] mobile:h-[unset]': isFromHomepage,
    }
  );

  const flightTitleContainerCompactView = !isEditable
    ? 'flex mb-[24px] gap-[4px] flex-col items-start min-w-[140px] max-w-[140px]'
    : flightTitleContainer;

  const navigate = useNavigate();
  const timeClass = classNames({
    'dark:text-grey-12 text-primary': !countdown?.delay,
    'dark:text-red text-red': countdown?.delay,
    'text-64 py-10 leading-[unset]': !isMobile,
    'flex items-center font-body-thin text-42 leading-[64px]': isCompactView,
    'max-w-[160px] min-w-[160px] py-4 flex items-center font-body-thin text-42 leading-[64px]':
      !isCompactView,
    hidden: flightStatus !== FLIGHT_STATUS.DEFAULT,
  });

  const flightStatusContainer = classNames('flex items-center', {
    'mb-[50px] mobile:mb-0': !error,
  });

  const airLineKey = getAirlineKey(flightName);

  return (
    <div
      className={classNames(
        isCompactView ? flightBlockContainerCompactView : flightBlockContainer,
        classNames({
          'cursor-none pointer-events-none': !isOnline,
        })
      )}
      {...others}
      onClick={() =>
        isEditable ? null : navigate(`/favourites/${flightId}/process`)
      }>
      <div
        className={
          isCompactView ? 'flex items-center justify-between' : 'flex flex-col'
        }>
        <div className="pl-24 pt-24 pr-24">
          <div
            className={
              isCompactView
                ? flightTitleContainerCompactView
                : flightTitleContainer
            }>
            <div className="flex mobile:gap-[2px] mobile:items-center mobile:min-w-[150px] mobile:max-w-[150px]">
              <h1 className={flightTitleClass}>{flightName}</h1>
              {FeatureFlagUtil.showFeature(
                process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
                []
              ) && (
                <PulseNotificationIcon
                  className={isCheckedIn ? 'pl-4' : 'pl-4 invisible'}
                />
              )}
            </div>
            {tags && (
              <Badge
                className={badgeClass}
                text={tags}
                textClassName={badgeTextClass}
              />
            )}
            <div className={flightStatusContainer}>
              <div className={routeClass}>
                <p className={originDestinationClass}>{origin}</p>
                <Icon variant="arrow" className={iconClass} />
                <p className={originDestinationClass}>{destination}</p>
              </div>
              {!isCompactView &&
                flightStatus &&
                flightStatus !== FLIGHT_STATUS.DEFAULT && (
                  <div className="ml-4">
                    <Badge
                      {...BADGE_CONFIG[
                        isMobile ? flightStatus + '_NO_TEXT' : flightStatus
                      ]}
                    />
                  </div>
                )}
            </div>
          </div>
          {error && (
            <div className={errorClass}>
              <Badge {...ERROR_BADGE(error)} />
            </div>
          )}

          {!isCompactView && (
            <div className={isEditable ? 'hidden' : 'relative'}>
              {isMobile
                ? renderFlightDetailsMobile()
                : renderFlightDetailsTabletAndDesktop()}
            </div>
          )}
        </div>
        <div className={isEditable ? 'hidden' : ''}>
          <div
            className={classNames('flex pl-6 pr-6 relative', {
              'pr-0': isCompactView,
            })}>
            <p className={timeClass}>{timeLeft}</p>
            {isCompactView &&
              flightStatus &&
              flightStatus !== FLIGHT_STATUS.DEFAULT && (
                <div className="flex items-center">
                  <Badge
                    {...BADGE_CONFIG[
                      isMobile ? flightStatus + '_NO_TEXT' : flightStatus
                    ]}
                  />
                </div>
              )}
          </div>
        </div>
      </div>

      {!isEditable && !isCompactView && isMobile && (
        <Tailwing
          isOpaque={flightStatus !== FLIGHT_STATUS.DEFAULT && !isEditable}
          airlineKey={airLineKey ?? ''}
          className="absolute right-[8%] bottom-[6px]"
          size="xs"
        />
      )}

      {!isEditable && !isCompactView && !isMobile && (
        <Tailwing
          isOpaque={flightStatus !== FLIGHT_STATUS.DEFAULT && !isEditable}
          airlineKey={airLineKey ?? ''}
          className="absolute right-[8%] bottom-[6px]"
          size="s"
        />
      )}

      <NotificationsOverlay
        isOpen={isEditable}
        isMutedLocally={!!isMutedLocally}
        isFavourite={!!isFavouriteLocally}
        onMuteNotification={() => {
          setIsMutedLocally((prev) => !prev);
          if (MY_FLIGHTS_2_ENABLED) {
            setFlightListEdit((prevFlightList) =>
              prevFlightList.map((flight) =>
                flight.flightId === flightId
                  ? {
                      ...flight,
                      notificationMuted: !flight.notificationMuted,
                    }
                  : flight
              )
            );
          } else {
            addFlightOnMuteList?.(flightId ?? '');
          }
        }}
        onChangeFavourite={() => {
          setIsFavouriteLocally((prev) => !prev);
          if (MY_FLIGHTS_2_ENABLED) {
            setFlightListEdit((prevFlightList) =>
              prevFlightList.map((flight) =>
                flight.flightId === flightId
                  ? {
                      ...flight,
                      isFavourite: !flight.isFavourite,
                      notificationMuted:
                        isMutedLocally ?? flight.notificationMuted,
                    }
                  : flight
              )
            );
          } else {
            addFlightOnRemoveList?.(flightId ?? '');
          }
        }}
        flightId={flightId ?? ''}
        isNotificationDisabled={MY_FLIGHTS_2_ENABLED && !isFavouriteLocally}
        role={role?._id ?? checkedInRole}
        onCheckinEdit={(role: string | null) => {
          setFlightListEdit((prevFlightList) =>
            prevFlightList.map((flight) =>
              flight.flightId === flightId
                ? {
                    ...flight,
                    checkedInRole: role,
                    isCheckedIn: !!role,
                  }
                : flight
            )
          );
        }}
      />
      <ProgressBar
        completedProcesses={
          flightStatus !== FLIGHT_STATUS.DEFAULT ? 0 : processes ?? 0
        }
        isDelayed={!!countdown?.delay}
      />
    </div>
  );
};

export default FlightBlock;
