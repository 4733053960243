import React from 'react';
import classNames from 'classnames';

interface IProgressBar {
  completedProcesses: number;
  isDelayed: boolean;
}

const ProgressBar = ({
  completedProcesses = 0,
  isDelayed,
  ...others
}: IProgressBar) => {
  const progress = (100 * completedProcesses) / 8;
  const progressBarClass =
    'h-[6px] rounded-b-8 bg-grey-12 dark:bg-grey-80 w-full';
  const progressBarFillClas = classNames(
    'h-[6px] rounded-bl-8',
    {
      'rounded-br-8': completedProcesses === 8,
    },
    {
      'bg-red': isDelayed,
      'bg-primary dark:bg-grey-12': !isDelayed,
    }
  );

  return (
    <div className={progressBarClass} {...others}>
      <div
        className={progressBarFillClas}
        style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
